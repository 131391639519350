import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <footer
      class="bg-green-900 pt-10 md:pt-0 md:pb-5 border-b-8 border-green-900"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" class="sr-only">
        Footer
      </h2>
      <div class="max-w-7xl mx-auto py-0 pt-10 md:py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div class="">
          <div class="grid grid-cols-1 gap-6 xl:col-span-4">
            <div class="md:grid md:grid-cols-4 md:gap-12 md:pb-0">
              <div>
                <a class="text-white font-bold" href="/">
                  <span class="sr-only">CleanUp</span>
                  <span class="font-display text-lg">CleanUp</span>
                </a>
                <p class="text-base text-white">info@cleanup.uk.com</p>

                <p class="text-xs text-white text-semibold mt-10">
                  Home page header photography provided by and <br />
                  copyright Peter Blandford.
                </p>
              </div>
              <div class="mt-12 md:mt-0">
                <h3 class="text-sm font-semibold text-white tracking-wider uppercase">
                  Join Us
                </h3>
                <ul class="mt-4 space-y-4">
                  <li>
                    <a
                      href="/events/sevenoaks-spring-clean"
                      class="text-base text-gray-300 hover:text-white"
                    >
                      Sevenoaks Spring Clean
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/groups/cleanupsevenoaks"
                      target="_blank"
                      class="text-base text-gray-300 hover:text-white"
                    >
                      Facebook group
                    </a>
                  </li>
                  <li>
                    <a
                      href="/blog"
                      class="text-base text-gray-300 hover:text-white"
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div>

              <div class="mt-12 md:mt-0">
                <h3 class="text-sm font-semibold text-white tracking-wider uppercase">
                  Sponsors
                </h3>
                <ul class="mt-4 space-y-4">
                  <li>
                    <a
                      href="https://sevenoakscnc.co.uk/"
                      target="_blank"
                      class="text-base text-gray-300 hover:text-white"
                    >
                      Sevenoaks CNC
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.thackraywilliams.com/"
                      target="_blank"
                      class="text-base text-gray-300 hover:text-white"
                    >
                      Thackray Williams
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.handelsbanken.co.uk/en/find-branch/sevenoaks"
                      target="_blank"
                      class="text-base text-gray-300 hover:text-white"
                    >
                      Handlesbanken
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.sevenoaks.gov.uk/"
                      target="_blank"
                      class="text-base text-gray-300 hover:text-white"
                    >
                      Sevenoaks District Council
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.sevenoakstown.gov.uk/"
                      target="_blank"
                      class="text-base text-gray-300 hover:text-white"
                    >
                      Sevenoaks Town Council
                    </a>
                  </li>
                </ul>
              </div>
              <div class="relative">
                <div class="max-h-80 max-w-52 hidden md:block z-20 md:mr-auto absolute bottom-0 right-0 ">
                  <StaticImage
                    className=""
                    src="../../images/oak_acorn.png"
                    placeholder="blurred"
                    alt="Oaky"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
