import React from "react"

const MissionStatement = () => {
  return (
    <section id="mission-statement">
      <div className="relative w-full mx-auto">
        <div className="relative">
          <blockquote className="bg-white py-36 px-10 shadow-md">
            <div className="max-w-3xl lg:max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p className="text-3xl leading-relaxed font-serif mb-10">
                An independent initiative run by volunteers committed to keeping
                our beautiful area clean and tidy.
              </p>
              <p className="font-sans mb-10 text-lg font-normal text-gray-400">
                We aim to reduce litter and fly tipping by raising awareness,
                educating future generations and organising events to clean up
                our environment.
              </p>
              <p className="font-sans mb-5 text-green-600">
                Please join on the{" "}
                <span className="font-bold">6th & 7th April 2024</span> to fight
                the filth!
              </p>
            </div>
          </blockquote>
        </div>
      </div>
    </section>
  )
}

export default MissionStatement
