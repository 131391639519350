import React from "react"

const Menu = () => {
  return (
    <>
      <div class="sticky top-0 z-50 bg-green-900 shadow-md">
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
          <div class="flex  align-middle justify-between items-center py-4 md:py-6 md:justify-start md:space-x-10">
            <div class="flex md:justify-start justify-between">
              <a class="text-white font-bold flex mt-2 md:mt-0" href="/">
                <span class="sr-only">CleanUp</span>
                <span class="font-display text-2xl md:text-xl">CleanUp</span>
              </a>
              <a
                href="https://www.facebook.com/groups/cleanupsevenoaks"
                target="_blank"
                rel="noopener"
                style={{ backgroundColor: "#4267B2" }}
                class="ml-8 rounded-md whitespace-nowrap inline-flex md:hidden items-center justify-center px-4 py-2 border border-transparent rounded-sm shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Join us on Facebook
              </a>
            </div>

            <div class="hidden md:flex items-center w-full justify-end">
              <a
                href="/blog"
                class="text-base font-semibold text-white hover:text-gray-300 ml-8"
              >
                Blog
              </a>
              <a
                href="/events/spring-clean-2024"
                class="text-base font-semibold text-white hover:text-gray-300 ml-8"
              >
                Spring Clean 2024
              </a>
              <a
                href="https://www.facebook.com/groups/cleanupsevenoaks"
                target="_blank"
                rel="noopener"
                style={{ backgroundColor: "#4267B2" }}
                class="ml-8 rounded-md whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-sm shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Join us on Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu
